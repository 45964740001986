import { buildCollection } from "firecms";
import { getEnumValues } from "../util";
import { getIDProperty, getTranslatedProperty, Translations } from "./shared";

interface MusclefocusInfo {
  id: string;
  name: Translations;
}

/**
 * Keep this in sync with the Firestore collection at `/musclefocus`.
 */
export enum Musclefocus {
  abductors = "abductors",
  abs = "abs",
  obliques = "obliques",
  serratus = "serratus",
  adductors = "adductors",
  back = "back",
  biceps = "biceps",
  calves = "calves",
  chest = "chest",
  forearms = "forearms",
  glutes = "glutes",
  hamstrings = "hamstrings",
  hipflexors = "hipflexors",
  lat = "lat",
  lowerback = "lowerback",
  neck = "neck",
  quadriceps = "quadriceps",
  frontDelt = "frontDelt",
  sideDelt = "sideDelt",
  rearDelt = "rearDelt",
  trapezius = "trapezius",
  triceps = "triceps",
  upperback = "upperback",
  upperChest = "upperChest",
  middleChest = "middleChest",
  lowerChest = "lowerChest",
  tibialisAnterior = "tibialisAnterior",
}

export const collectionMusclefocus = buildCollection<MusclefocusInfo>({
  customId: getEnumValues(Musclefocus),
  group: "Static Data",
  name: "Musclefocus",
  path: "musclefocus",
  icon: "PersonSearch",
  callbacks: {
    onPreSave: async (props) => {
      const values = props.values;

      return {
        ...values,
        id: props.entityId,
      };
    },
  },
  permissions: ({ authController }) => ({
    edit: true,
    create: true,
    delete: false,
  }),
  properties: {
    id: getIDProperty(),
    name: getTranslatedProperty({ name: "Name" }),
  },
});
